/*
 * @Author: 冯杰
 * @Date: 2021-10-12 20:33:23
 * @LastEditTime: 2021-11-08 11:18:45
 * @FileName: 终端门店红包提现明细
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';

export default {
  name: 'TerminalWithdraw',
  extends: TablePage,
  data() {
    return {
      // requestUrl: '/cps/v1/cashRecord/cashRecordReport/findTerminalByConditions',
      requestUrl: '/cps/v1/xf/red/packet/report/findWithdrawalByConditions',
      requestType: 'GET',
      searchFormData: {},
    };
  },
  components: {
    Modal,
  },
  methods: {
    setColumn(col) {
      const rowData = col;
      return rowData;
    },
    modalClick({ val, row }) {
      if (val.code === 'batch_payment') {
        this.$confirm('是否要按照当前搜索条件批量修改开票状态？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            request.post('/cps/v1/xf/red/packet/report/updateByConditions', { ...this.searchFormData }).then((res) => {
              if (res.success) {
                this.$message({
                  type: 'success',
                  message: '操作成功',
                });
                this.getList();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            });
          });
      }
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('terminal_withdrawal_product_series');
  },
};
